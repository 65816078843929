@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply bg-green-500 hover:bg-green-600 border border-green-500 disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-200 text-white font-medium py-2 px-4 my-2 rounded-md flex items-center justify-center;
  }

  .btn-secondary {
    @apply bg-transparent hover:bg-gray-100 border-green-500 text-green-500 disabled:hover:bg-gray-100;
  }

  .btn-icon {
    @apply text-black bg-transparent border-none hover:bg-gray-100 px-2 pt-2 items-center justify-center disabled:hover:bg-gray-100;
  }

  .input-field {
    @apply mt-1 px-3 py-2 block w-full placeholder-gray-400 text-gray-700 bg-white rounded-md text-sm shadow-sm border border-gray-400 focus:outline-none focus:ring-1 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:opacity-50 disabled:bg-gray-200;
  }

  .list {
    @apply list-none text-left max-h-96 overflow-y-auto rounded-lg;
  }

  .list-item {
    @apply bg-gray-200 first:mt-0 last:mb-0 my-2 p-2 rounded-lg;
  }

  .container {
    @apply mx-auto my-auto bg-white shadow p-5 rounded-lg;
  }

  .container-inside {
    @apply shadow-none border-green-500/50 px-3 py-2 border;
  }
}
